<template>
  <!--  <div class="demand-detail">-->
  <!--    <div class="dd-body">-->
  <!--      <div class="dd-body-left">-->
  <!--        <div class="ddbl-title ellipse-1">{{detailInfo&&detailInfo.title}}</div>-->
  <!--        <div class="ddbl-des">-->
  <!--          <span>预算金额：</span>-->
  <!--          <span style="color:#FF6A00 ">协商确定</span>-->
  <!--          <span style="margin-left: 30px">需求类型：{{detailInfo&&detailInfo.requirementTypeName}}</span>-->
  <!--          <span style="margin-left: 30px">需求日期：{{detailInfo&&detailInfo.updatedTime}}</span>-->
  <!--        </div>-->
  <!--        <div class="ddbl-content">-->
  <!--          {{detailInfo&&detailInfo.content}}-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <div class="dd-body-right">-->
  <!--        <div class="ddbr-title">最新需求</div>-->
  <!--        <div style="padding: 0 15px">-->
  <!--          <div-->
  <!--            @click="getDemandDetail(item.id)"-->
  <!--            v-for="item in newestList" :key="item.id"-->
  <!--            style="margin-top: 14px;border: 1px solid  rgba(220, 220, 220, .5);height: auto;cursor: pointer">-->
  <!--            <span class="xmsb_circle"-->
  <!--                  style=" vertical-align:top;top:-4px;position:relative;padding-top:5px;padding-left:5px;color:white;min-height: 50px;width: 40px;background-color:#fc7c40;display: inline-block;margin-left: 10px;font-size: 14px">-->
  <!--                {{item.requirementTypeName}}-->
  <!--              </span>-->
  <!--            <div style="width: 240px;height: 100%;padding: 10px;display: inline-block">-->
  <!--              <div class="xqmc ellipse-2">-->
  <!--                <span>{{item.title}}</span></div>-->
  <!--              <div class="ellipse-2" style="margin-top: 10px;margin-bottom: 20px">-->
  <!--                {{item.content}}-->
  <!--              </div>-->
  <!--              <div style="font-size: 14px"><span>预算金额：</span><span style="color: #FF6A00">协商确定</span></div>-->
  <!--              <div style="font-size: 14px"><span>需求日期：{{item.updatedTime.split(" ")[0]}}</span></div>-->
  <!--            </div>-->

  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
  <div class="demand-detail">
    <div class="dd-head">
      <div class="dd-button" @click="goEnterPriseCenter"></div>
    </div>

    <div class="dd-content">
      <div class="dd-title">{{ detailInfo && detailInfo.title }}</div>
      <div class="dd-des">需求类型：
        <div :class="getDemandTypeClass">{{detailInfo.requirementTypeName}}</div>
        <div style="margin-left: 50px">{{detailInfo.updatedTime}}</div>
        <div style="margin-left: 55px">预算金额：</div>
        <div style="color: rgba(255, 114, 0, 1)">协商确定</div>
      </div>
      <div class="dd-content-text">{{detailInfo.content}}</div>
    </div>


  </div>
</template>

<script>
import {getDemandDetailById, getDemandListByType} from "../../plugins/api/demandApi";
import {mapGetters} from "vuex";

export default {
  name: 'demandDetail',
  data() {
    return {
      detailInfo: {},//详情信息
      newestList: [],//最新需求列表
    }
  },
  computed: {
    ...mapGetters({
      token: 'userStore/token',
      userName: 'userStore/userName',
      userId: 'userStore/userId',
      enterpriseState: 'userStore/enterpriseState'
    }),
    getDemandTypeClass() {
      switch (this.detailInfo.requirementType) {
        case "PROJECT_DECLARATION":
          return 'dd-des-tagA'
        case "PATENT_APPLICATION":
          return 'dd-des-tagB'
        case "INTELLECTUAL_PROPERTY_RIGHT":
          return 'dd-des-tagC'
        case "LEGAL_AID":
          return 'dd-des-tagD'
        case "ANALYSIS_OF_PATENT_INFORMATION":
          return 'dd-des-tagE'
        case "EVALUATION_OF_PATENT_VALUE":
          return 'dd-des-tagF'
        case "TECHNICAL_AUTOMATIC_MATCHING":
          return 'dd-des-tagG'
        case "OTHER_REQUIREMENTS":
          return 'dd-des-tagH'
      }
    }
  },
  mounted() {
    this.getDemandDetail(this.$route.query.id);
    // this.getNewestDemandList();
  },
  methods: {
    //获取需求详情
    async getDemandDetail(id) {
      const params = {
        requirementId: id
      }
      const json = await getDemandDetailById(params);
      if (json && json.code === 0) {
        this.detailInfo = json.result
      }
    },
    async getNewestDemandList() {
      const params = {
        pageSize: 3,
        pageNum: 1
      }
      const json = await getDemandListByType(params);
      if (json && json.code === 0) {
        if (json.result && json.result.list && json.result.list.length > 0) {
          this.newestList = json.result.list

        }
      }
    },
    //校验是否登录
    checkIsLogin() {
      if (!this.token) {
        this.$router.push({
          path: `/login`,
          params: {
            type: 1
          }
        });
        return
      }
      if (this.enterpriseState === 'UNCERTIFIED') {
        this.$router.push({
          path: '/home/EnterpriseCertification'
        });
      }
    },
    goEnterPriseCenter() {
      this.checkIsLogin()
      this.enterpriseState === 'CERTIFIED' && this.token && this.$router.push({
        path: "/enterprise/enterprise-school-connection",
      });
    },
  }
}
</script>

<style scoped lang="css">
.demand-detail {
  width: 1200px;
  margin: 30px auto 50px auto;
  box-shadow: 0 4px 5px 0 rgba(136, 176, 240, 0.15);
  border-radius: 10px;
}

.dd-head {
  height: 250px;
  background-image: url("https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/public/202203/lyg_demand_hall_bg.png");
  background-size: 100% 100%;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.dd-button {
  position: absolute;
  left: 487px;
  top: 98px;
  width: 104px;
  height: 28px;
  border-radius: 14px;
  cursor: pointer;
}

.dd-content {
  min-height: 515px;
  background: #FFFFFF;
  box-shadow: 0 4px 5px 0 rgba(136, 176, 240, 0.15);
  border-radius: 0 0 10px 10px;
  padding-left: 30px;
  padding-right: 30px;
}

.dd-title {
  font-size: 20px;
  font-weight: 400;
  color: #333333;
  margin-top: 50px;
}

.dd-des {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #859BBD;
  margin-top: 20px;
}

.dd-content-text{
  font-size: 16px;
  margin-top: 30px;
  font-weight: 400;
  color: #666666;
}

.dd-des-tagA {
  padding: 4px 7px;
  background: #CEDFF8;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #1062DF;
  flex-shrink: 0;
}

.dd-des-tagB {
  padding: 4px 7px;
  background: rgba(222, 206, 250, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(138, 74, 245, 1);
  flex-shrink: 0;
}

.dd-des-tagC {
  padding: 4px 7px;
  background: rgba(186, 238, 232, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(15, 178, 161, 1);
  flex-shrink: 0;
}

.dd-des-tagD {
  padding: 4px 7px;
  background: rgba(253, 221, 212, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(244, 107, 70, 1);
  flex-shrink: 0;
}

.dd-des-tagE {
  padding: 4px 7px;
  background: rgba(206, 250, 220, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(26, 181, 75, 1);
  flex-shrink: 0;
}

.dd-des-tagF {
  padding: 4px 7px;
  background: rgba(212, 215, 246, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(87, 98, 241, 1);
  flex-shrink: 0;
}

.dd-des-tagG {
  padding: 4px 7px;
  background: rgba(245, 242, 210, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(169, 159, 50, 1);
  flex-shrink: 0;
}

.dd-des-tagH {
  padding: 4px 7px;
  background: rgba(253, 219, 246, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(244, 16, 198, 1);
  flex-shrink: 0;
}
</style>
